<template>
  <div v-loading="!baseInfo" style="min-height: 400px">
    <el-descriptions v-if="baseInfo" title="" :column="2" border>
      <el-descriptions-item label="场地名称">
        {{ baseInfo.name }}
      </el-descriptions-item>
      <el-descriptions-item label="所属架构">
        {{ baseInfo.organization_name }}
      </el-descriptions-item>
      <el-descriptions-item label="场地设施">
        {{ baseInfo.facilities.map((el) => el.name).join('、') }}
      </el-descriptions-item>
      <el-descriptions-item label="容纳人数">
        {{ baseInfo.people_number || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="场地地址">
        {{ baseInfo.address.name }}（{{ baseInfo.address.address }}）
      </el-descriptions-item>
      <el-descriptions-item label="开放时间">
        {{
          baseInfo.reserve_times && baseInfo.reserve_times.length ? '' : '--'
        }}
        <template v-for="(rt, rtI) in baseInfo.reserve_times">
          <p>
              <span v-if="baseInfo.reserve_times.length > 1"
              >时间{{ chSort[rtI] || rtI + 1 }}：</span
              >
            {{ formatDate(rt.start_date * 1000, 'YYYY/MM/DD') }}~{{formatDate(rt.end_date * 1000, 'YYYY/MM/DD')}}</p>
          <template v-if="rt.mode === 'week'">
            <template v-for="(item, index) in rt.weektimeData">
              <p
                :class="'day' + item.day"
                :style="
                  true || index > 0 && baseInfo.reserve_times.length > 1
                    ? 'text-indent: 4.2em'
                    : ''
                "
              >
                <span class="week-day">
                  {{
                    [
                      '星期日',
                      '星期一',
                      '星期二',
                      '星期三',
                      '星期四',
                      '星期五',
                      '星期六',
                    ][item.day]
                  }}：
                </span>
                {{ parseToTime(item.begin) }}-{{ parseToTime(item.end) }}
              </p>
            </template>
            <span v-if="!rt.weektimeData.length">{{ '' | placeholder }}</span>
            <p v-else-if="rt.inclusions.length" class="day-1" style="text-indent: 4.2em;">
              <span>特定时间：</span>
              <span v-for="ex in rt.inclusions" :key="ex.id">{{
                formatDate(ex.start_date * 1000,'YYYY/MM/DD')
                }} {{parseToTime(ex.start_time)}}~{{
                formatDate(ex.end_date * 1000,'YYYY/MM/DD')
                }} {{parseToTime(ex.end_time)}}</span>
            </p>
          </template>
          <template v-if="rt.mode === 'day'">
            <template v-for="(item, index) in rt.daytimeData">
              <p
                :class="'day' + item.day"
                :style="
                  index > 0 && baseInfo.reserve_times.length > 1
                    ? 'text-indent: 4.2em'
                    : ''
                "
              >
                <span v-if="index === 0 && baseInfo.reserve_times.length > 1"
                  >时间{{ chSort[rtI] || rtI + 1 }}：</span
                >
                <span class="week-day">
                  {{
                    [
                      '星期日',
                      '星期一',
                      '星期二',
                      '星期三',
                      '星期四',
                      '星期五',
                      '星期六',
                    ][item.day]
                  }}：
                </span>
                {{ parseToTime(item.begin) }}-{{ parseToTime(item.end) }}
              </p>
            </template>
            <span v-if="!rt.daytimeData.length">{{ '' | placeholder }}</span>
          </template>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="使用权限">
        {{ baseInfo.reserve_text }}
      </el-descriptions-item>
      <el-descriptions-item
        label=""
        label-class-name="empty"
        content-class-name="empty"
      >
      </el-descriptions-item>
      <el-descriptions-item label="单次可预订最小时段">
        {{ baseInfo.min_reserve_text }}
      </el-descriptions-item>
      <el-descriptions-item label="审批">
        {{ baseInfo.approval_text }}
      </el-descriptions-item>
<!--      <el-descriptions-item label="可预订范围"> {{baseInfo.reserve_time_scope_text}} </el-descriptions-item>-->
      <el-descriptions-item label="单次可预订最长时段">
        {{ baseInfo.max_reserve_text }}
      </el-descriptions-item>
      <el-descriptions-item label="备注">
        {{ baseInfo.remark || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="关联审批模板">
        {{ baseInfo.tmpl_name }}
      </el-descriptions-item>
      <el-descriptions-item label="图片">
        <el-image
          v-for="image in baseInfo.images"
          :key="image"
          :src="fixImageUrl(image, 300)"
          class="image"
          fit="cover"
          :preview-src-list="baseInfo.images"
        ></el-image>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { getVenueBaseInfo } from '../api'
import chSort from '../data/ch-sort'
import { parseToTime } from '../js/time-tool'
import { fixImageUrl, formatDate } from '../../../base/utils/tool'
export default {
  data() {
    return {
      baseInfo: null,
      chSort,
    }
  },
  computed: {
    venueId() {
      return this.$route.params.id
    },
  },
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    parseToTime,
    fixImageUrl,
    formatDate,
    init() {
      getVenueBaseInfo(this.venueId)
        .then(({ data, msg }) => {
          this.baseInfo = this.$mergeModel(
            {
              id: '',
              name: '',
              can_whole_user_reserve: 1,
              reserve_times: [
                {
                  id: '',
                  mode: 'week',
                  end_date: 0,
                  inclusions: [
                    {
                      start_date: 0,
                      end_date: 0,
                      start_time: 0,
                      end_time: 0,
                    },
                  ],
                  start_date: 0,
                  daytimeData: [
                    {
                      day: 1,
                      end: 0,
                      begin: 0,
                    },
                  ],
                  weektimeData: [
                    {
                      day: 1,
                      end: 0,
                      begin: 0,
                    },
                  ],
                },
              ],
              address: {
                lat: '',
                lng: '',
                name: '',
                address: '',
              },
              people_number: 1000,
              images: [''],
              remark: '',
              use_default_approval_tmpl: 0,
              facilities: [{ id: '', name: '' }],
              category: { id: '', name: '' },
              organization_name: '',
              approval_text: '',
              tmpl_name: '',
              min_reserve_text: '',
              max_reserve_text: '',
              reserve_text: '',
              reserve_time_scope_text: ''
            },
            data
          )
          this.$nextTick(() => {
            const th = document.querySelector('tbody:nth-child(3) td + th')
            const td = document.querySelector(
              'tbody:nth-child(3) td:last-child'
            )
            th.setAttribute('rowspan', 2)
            td.setAttribute('rowspan', 2)

            const th4 = document.querySelector(
              'tbody:nth-child(4) th:first-child'
            )
            const td4 = document.querySelector(
              'tbody:nth-child(4) th:first-child + td'
            )

            const trFor4 = document.createElement('tr')
            trFor4.appendChild(th4)
            trFor4.appendChild(td4)
            document.querySelector('tbody:nth-child(3)').appendChild(trFor4)
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.day1 + .day1,
.day2 + .day2,
.day3 + .day3,
.day4 + .day4,
.day5 + .day5,
.day6 + .day6,
.day0 + .day0,
.day-1 + .day-1
{
  text-indent: 4.2 * 2em !important;

  .week-day {
    display: none;
  }
}

::v-deep .el-descriptions {
  td:nth-child(2n) {
    width: 34%;
  }

  .empty {
    height: 0;
    display: none;
  }
}

.image {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
</style>
